import * as THREE from 'three'

export const createVideoTexture = (videoSrc: string) => {
  const video = document.createElement("video")
  video.src = videoSrc
  video.playsInline = true
  video.loop = true
  video.setAttribute("style", "position: absolute; top: 0; left: 0; opacity: 0; pointer-events: none; max-width: 100vw; max-height: 90vh; z-index: 5;")
  document.body.appendChild(video)

  const texture = new THREE.VideoTexture(video)
  texture.flipY = false
  texture.userData.video = video
  texture.userData.videoPlaying = false

  return texture
}
