import { action, makeObservable, observable, runInAction } from "mobx"

const getPosition = (options?: PositionOptions): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => 
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

class GeoStore {

  position?: GeolocationCoordinates
  status: "init" | "loaded" | "reject" | "skipped" = "init"
  city = ""
  weather: any = null

  constructor() {
    makeObservable(this, {
      city: observable,
      weather: observable,
      status: observable,
      skip: action.bound
    })
  }

  init() {
    if (window.innerWidth > 1024 ) return
    if (this.status === "loaded") return
    getPosition()
      .then(action((pos) => {
        this.position = pos.coords
        this.status = "loaded"
        this.getWeather()
        // this.getCity()
      }))
      .catch(action(() => {
        this.status = "reject"
      }))
  }

  async getWeather() {
    const lat = this.position!.latitude
    const lon = this.position!.longitude
    const _resp = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&units=metric&lang=ru&appid=119aea49394825fc79227b1cd8282e46`)
    const resp = await _resp.json()

    runInAction(() => {
      this.city = resp.city.name
      this.weather = resp.list.slice(0, 4)
    })
  }

  async getCity() {
    const lat = this.position!.latitude
    const lon = this.position!.longitude
    const _resp = await fetch(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`, {
      body: JSON.stringify({ lat, lon }),
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token 98641e05c7731a5bfb9b7818dfed994d1bfb68e5"
       },
      method: "POST"
    })
    const resp = await _resp.json()

    runInAction(() => {
      this.city = resp.suggestions[0].data.city || resp.suggestions[0].data.sub_area || resp.suggestions[0].data.area || resp.suggestions[0].data.region
    })

  }

  skip() {
    this.status = "skipped"
  }

}

export default GeoStore