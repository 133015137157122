import * as THREE from 'three'

export const applyForMeshChildren = (obj: THREE.Object3D, callback: (obj: THREE.Mesh) => void) => {

  if (obj.type === "Mesh") {
    callback(obj as THREE.Mesh)
  }
  if (!obj.children || obj.children.length === 0) return

  for (let child of obj.children) {
    applyForMeshChildren(child, callback)
  }
}

export const findRecursive = (obj: THREE.Object3D | null, cond: RegExp | string | ((obj: THREE.Object3D) => boolean)): THREE.Object3D[] => {
  const arr: THREE.Object3D[] = []
  if (!obj || !obj.visible) return arr
  
  for (let child of obj.children) {
    if (typeof cond === "string" && cond === child.name) {
      arr.push(child)
    }
    if (typeof cond === "function" && cond(child)) {
      arr.push(child)
    }
    if (typeof cond === "object" && cond.test(child.name)) {
      arr.push(child)
    }

    if (child.children && child.children.length > 0) {
      arr.push(...findRecursive(child, cond))
    }
  }

  return arr
}
