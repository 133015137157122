import styles from './style.module.sass'
import cn from 'classnames'

const LoaderPage = () => {

  return (
    <div className={cn(styles.container, 'h')}>
      <img src={require("./logo.png")} className={styles.img}/>
      <div>Загрузка...</div>
    </div>
  )
}

export default LoaderPage
