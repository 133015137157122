import * as THREE from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { createTransparentMaterial } from 'utils/chroma-shader';
import { findRecursive } from 'utils/mesh';
import { addRoomLight, clone, createPlane } from './utils';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import MainStore from 'store/store';
import { when } from 'mobx';
import dayjs from 'dayjs';
import { wrapText } from 'utils/canvas';

type Asset = THREE.Texture & GLTF
type Assets = { [key in keyof typeof assets]: Asset }
type Options = { marker: string, mainStore: MainStore }

export const assets = {
  model: "./assets/plane.glb",
  lightning: "./assets/weather/lightning.glb",
  rainy: "./assets/weather/rainy.glb",
  snow: "./assets/weather/snow.glb",
  sun: "./assets/weather/sun.glb",
  cloudy: "./assets/weather/cloudy.glb",
  fog: "./assets/weather/fog.glb",
  june: "./assets/06_june.glb",
  july: "./assets/07_july.glb",
  aug: "./assets/08_aug.glb",
  sep: "./assets/09_sep.glb",
  oct: "./assets/10_oct.glb",
  nov: "./assets/11_nov.glb",
  jan: "./assets/01_jan.glb",
  feb: "./assets/02_feb.glb",
  march: "./assets/03_march.glb",
  april: "./assets/04_april.glb",
  may: "./assets/05_may.glb",
  dec: "./assets/12_dec.glb",

}

export const assetsSign = {
  jan: 1863492 * 14
}

let canvas: HTMLCanvasElement
let canvasTexture: THREE.Texture
export const initScene = async (scene: THREE.Scene, assets: Assets, { marker, mainStore }: Options) => {
  addRoomLight(scene)

  const addModel = (weatherId: number) => {
    let model;
    switch(weatherId) {
      case 800:
        model = clone(assets.sun.scene)
      break;
      case 801:
      case 802:
        model = clone(assets.cloudy.scene)
      break;
      case 803:
      case 804:
      case 741:
        model = clone(assets.fog.scene)
      break;
      case 500:
      case 501:
      case 502:
      case 503:
        model = clone(assets.rainy.scene)
      break;
      case 200:
      case 201:
      case 202:
      case 210:
      case 211:
      case 212:
        model = clone(assets.lightning.scene)
      break;
      case 600:
      case 601:
      case 602:
        model = clone(assets.snow.scene)
      break;
      default:
        model = clone(assets.sun.scene);
    }


    
    model.scale.set(0.18, 0.18, 0.18).multiplyScalar(2)
    model.position.set(-0.07, 0, 0.35).multiplyScalar(2)
    model.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    scene.add(model)
  }

    let modelM;
    if (marker === '1') {
      modelM = clone(assets.jan.scene);
      modelM.scale.set(0.65, 0.75, 0.75).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)      
    } else if (marker === '2') {
      modelM = clone(assets.feb.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0.03, -0.4, 0.03).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '3') {
      modelM = clone(assets.march.scene);
      modelM.scale.set(0.55, 0.55, 0.55).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0.04).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '4') {
      modelM = clone(assets.april.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(-0.05, 0, 0.05).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '5') {
      modelM = clone(assets.may.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0.05, 0.02, 0.03).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '6') {
      modelM = clone(assets.june.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0.05).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '7') {
      modelM = clone(assets.july.scene);
      modelM.scale.set(0.65, 0.75, 0.75).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '8') {
      modelM = clone(assets.aug.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0.03).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '9') {
      modelM = clone(assets.sep.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0.05, 0, 0).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '10') {
      modelM = clone(assets.oct.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0, 0, 0.05).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else if (marker === '11') {
      modelM = clone(assets.nov.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(0, -0.1, 0.05).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    } else {
      modelM = clone(assets.dec.scene);
      modelM.scale.set(0.65, 0.65, 0.65).multiplyScalar(2)
      modelM.position.set(-0.03, 0.05, 0.05).multiplyScalar(2)
      modelM.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
    }
    
    scene.add(modelM)

  // {
  //   const material = new THREE.MeshBasicMaterial({ map: assets.anim })
  // const plane = new THREE.Mesh(new THREE.PlaneGeometry(), material)
  // plane.scale.set(1, 0.7, 1)
  
  // plane.rotateX(-90 * THREE.MathUtils.DEG2RAD)
  // plane.rotateZ(180 * THREE.MathUtils.DEG2RAD)
  //  // (левоправо\нанасотнас\верхниз) 
  // plane.position.add(new THREE.Vector3(0, 0, -0.2))

  //   scene.add(plane)
  // }

  // scene.userData.videoTextures = [ assets.anim]
  // assets.anim.userData.video.muted = true
  // assets.anim.userData.videoPlaying = true

  if (!canvas) {
    canvas = document.createElement("canvas")
    canvas.width = 300
    canvas.height = 300
    canvasTexture = new THREE.CanvasTexture(canvas)
    const ctx = canvas.getContext('2d')!

    when(() => !!mainStore.geo.city && !!mainStore.geo.weather, () => {
      ctx.fillStyle = "#235436"

      const cityName = mainStore.geo.city
      const fontSize = Math.min(310 / cityName.length, 40)
      ctx.font = `400 ${fontSize}px "Evolventa"`
      ctx.fillText(cityName, 10, 40, 300)
      ctx.font = '30px "Evolventa"'
      ctx.fillText(mainStore.geo.weather[0].main.temp.toFixed(0)+"°", 10, 80, 400)

      ctx.font = '16px "Evolventa"'
      let height = 100
      const [ lines ] = wrapText(mainStore.geo.weather[0].weather[0].description, 140, ctx)
      for (let line of lines) {
        ctx.fillText(line, 10, height, 400)
        height += 20
      }

      ctx.font = '16px "Evolventa"'
      ctx.textAlign = "center"
      height += 10

      ctx.fillText(dayjs(mainStore.geo.weather[1].dt_txt).format("HH:mm"), 30, height, 300)
      ctx.fillText(mainStore.geo.weather[1].main.temp.toFixed(0)+"°", 30, height+20, 300)

      ctx.fillText(dayjs(mainStore.geo.weather[2].dt_txt).format("HH:mm"), 80, height, 300)
      ctx.fillText(mainStore.geo.weather[2].main.temp.toFixed(0)+"°", 80, height+20, 300)

      ctx.fillText(dayjs(mainStore.geo.weather[3].dt_txt).format("HH:mm"), 130, height, 300)
      ctx.fillText(mainStore.geo.weather[3].main.temp.toFixed(0)+"°", 130, height+20, 300)
    })
  }
  when(() => !!mainStore.geo.weather, () => {
    addModel(mainStore.geo.weather[0].weather[0].id)
  })
  const plane = createPlane(1)
  plane.position.set(-0.185, 0.01, 0.85)
  plane.scale.set(0.7, 0.7, 0.7)
  plane.material = new THREE.MeshBasicMaterial({ map: canvasTexture, transparent: true })
  scene.add(plane)

}
