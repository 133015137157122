import { action, makeObservable, observable } from "mobx";
import settings from "settings";
import MainStore from "store/store";

class ArStore {

  status: "init" | "loaded" | "error" = "init"
  videoElement!: HTMLVideoElement
  videoCanvas!: HTMLCanvasElement
  arAugmentedVision: any

  private mainStore: MainStore
  constructor(mainStore: MainStore) {
    this.mainStore = mainStore
    makeObservable(this, {
      status: observable,
      setStatus: action,
      setVideoRef: action.bound
    })
  }

  init() {
    this.arAugmentedVision = new ar.AugmentedVision();
    const augmentedVisionConfig = {
        libPath: 'ar',
        assetsPath: 'markers',   
        fovY: 60,
        classifier_fns: settings.markers,  
        marker_mode: 0
    }

    this.arAugmentedVision.initialize(
      augmentedVisionConfig,
      () => this.setStatus("loaded"),
      (error: any) => {
        console.log(error)
        this.setStatus("error")
      }
    )

    // Create video canvas
    this.videoCanvas = document.createElement('canvas')
    this.videoCanvas.style.display = 'none'
  }

  track() {
    const ctx = this.videoCanvas.getContext('2d')
    if (!ctx) return
    ctx.drawImage(this.videoElement, 0, 0, this.videoCanvas.width, this.videoCanvas.height)
    this.arAugmentedVision.track(this.videoCanvas)
    return this.arAugmentedVision
  }
  
  setStatus(status: typeof this.status) {
    this.status = status
  }

  setVideoRef(video: HTMLVideoElement) {
    this.videoElement = video
    if (video.src) return
    video.srcObject = this.mainStore.camera.stream
  }

  onResize() {
    this.videoCanvas.width = this.videoElement.videoWidth;
    this.videoCanvas.height = this.videoElement.videoHeight;
  }
}

export default ArStore