import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { action, computed, makeObservable, observable } from "mobx"
import { assets, assetsSign, initScene } from 'scene'
import * as THREE from 'three'
import MainStore from 'store/store';
import { applyForMeshChildren } from 'utils/mesh';
import { createVideoTexture } from 'utils/assets';
import settings from 'settings'

class SceneLoaderStore {

  loadStatus: "init" | "loaded" | "complete" | "error" = "init"
  step = 0  
  assets: any
  scenes: { [key: string]: THREE.Scene } = {}

  mainStore: MainStore
  constructor(mainStore: MainStore) {
    this.mainStore = mainStore
    makeObservable(this, {
      loadStatus: observable,
      step: observable,
      status: computed,
      setStatus: action
    })
  }

  async init() {
    this.dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.1/")
    this.gltfLoader.setDRACOLoader(this.dracoLoader)
    await new Promise(res => setTimeout(res, 500))

    const _assets = await Promise.all(Object.keys(assets).map(key => this.loadAsset((assets as any)[key])))
    const loadedAssets = Object.fromEntries(_assets.map((asset, index) => [ Object.keys(assets)[index], asset ]))
    this.assets = loadedAssets

    for (let [ key, asset ] of Object.entries(loadedAssets)) {
      if (asset.parser && key in assetsSign) {
        // console.log(asset.parser.json.buffers[0].byteLength)
        if (asset.parser.json.buffers[0].byteLength !== (assetsSign as any)[key]/14) {
          this.setStatus("error")
          return
        }
      }
    }

    this.scenes = {}
    for (let marker of settings.markers) {
      const scene = new THREE.Scene()
      this.scenes[marker] = scene
      await initScene(scene, loadedAssets as any, { marker, mainStore: this.mainStore })
    }

    await new Promise(res => setTimeout(res, 200))
    this.setStatus("loaded")
    for (let marker of settings.markers) {
      this.scenes[marker].visible = false
    }
  }

  setStatus(status: typeof this.loadStatus) {
    this.loadStatus = status
  }

  get status() {
    if (this.loadStatus !== "loaded") return this.loadStatus
    if (this.mainStore.ar.status !== "loaded") return this.mainStore.ar.status
    return this.loadStatus
  }

  continue() {
    this.setStatus("complete")
    for (let asset of Object.values<any>(this.assets)) {
      if (asset.userData.video && asset.userData.videoPlaying) {
        asset.userData.video.play()
      }
    }
  }

  private loadAsset(assetSrc: string): Promise<any> {
    const name = assetSrc.toLowerCase()
    if (name.endsWith("glb")) {
      return this.loadGlb(assetSrc)
    }

    if (name.endsWith('jpg') || name.endsWith('gif') || name.endsWith('png') || name.endsWith('webp')) {
      return this.loadTexture(assetSrc)
    }
    if (name.endsWith('mp4')) {
      return this.loadVideo(assetSrc)
    }

    return Promise.resolve()
  }
  
  dracoLoader = new DRACOLoader()
  gltfLoader = new GLTFLoader();
  loadGlb = async (objSrc: string) => {
    const gltf = await this.gltfLoader.loadAsync(objSrc)
    gltf.scene.frustumCulled = false
    applyForMeshChildren(gltf.scene, mesh => {
      (mesh.material as THREE.MeshStandardMaterial).metalness = 0
    })
    if (gltf.animations.length > 0) {
      const mixer = new THREE.AnimationMixer(gltf.scene)
      gltf.animations.forEach(clip => mixer.clipAction(clip).play())
      gltf.scene.userData.mixer = mixer
      gltf.scene.userData.animations = gltf.animations
    }
    return gltf
  }

  textureLoader = new THREE.TextureLoader();
  loadTexture = async (src: string) => {
    return await this.textureLoader.loadAsync(src)
  }

  loadVideo = async (src: string) => {
    return createVideoTexture(src)
  }


}

export default SceneLoaderStore