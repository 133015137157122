import styles from './TabBar.module.sass'
import { useState } from 'react'
import icon1 from './icons/icon-ar.svg'
import icon2 from './icons/icon-calend.svg'
import icon3 from './icons/icon-temp.svg'
import cn from 'classnames'

const TabBar = () => {

  const [ currentTab, setCurrentTab ] = useState(0)
  const tabs = [
    {
      icon: icon1,
      title: "Дополненная реальность",
      content: "Посмотри, иллюстрации в календаре оживают! Наведи камеру смартфона на календарь и убедись сам!"
    },
    {
      icon: icon2,
      title: "Календарь",
      content: "Каждый месяц оживает по-своему. Следи за календарем, ведь 1-го числа каждого месяца тебя ждет новое волшебство!"
    },
    {
      icon: icon3,
      title: "Погода",
      content: "Это еще не все! \nКаждый день ты сможешь узнать прогноз погоды прямо на календаре!  \n\nМеханика простая: наводишь камеру на календарь и видишь, какая погода в твоем городе прямо сейчас! Попробуй!"
    }
  ]

  return (
    <div className={styles.tabBar}>
      <div className={styles.tabs}>
        { tabs.map((item, index) => (
          <button key={index} className={currentTab === index? styles.active: ""} onClick={() => setCurrentTab(index)}>
            <img src={item.icon} alt="item-icon" />
          </button>
        ))}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{ tabs[currentTab].title }</div>
        <span>{ tabs[currentTab].content }</span>
      </div>
      <div className={styles.markers}>
        { tabs.map((item, index) => (
          <div key={index} className={cn(styles.marker, currentTab === index && styles.active)}></div>
        ))}
      </div>
    </div>
  )
}

export default TabBar