import { useEffect } from 'react'
import SceneUI from 'scene/ui'
import { useMainStore } from 'store'
import styles from './style.module.sass'

const ArPage = () => {

  const { ar, render } = useMainStore()
  useEffect(() => render.init(), [])

  return (
    <div className={styles.container}>
      <video playsInline autoPlay ref={ar.setVideoRef} loop muted />
      <canvas ref={render.setCanvasRef} onClick={e => render.onCanvasClick(e)}/>
      <div className={styles.ui}>
        <SceneUI/>
      </div>
    </div>
  )

}

export default ArPage