import { observer } from "mobx-react-lite"
import { useMainStore } from "store"
import LoaderPage from "pages/preloader"
import CameraRejectedPage from "pages/preloader/camera-rejected"
import GettingStartedPage from "pages/preloader/getting-started"
import DesktopPage from "pages/preloader/desktop"
import ArPage from "pages/ar"
import GeoRejectedPage from "pages/preloader/geo-rejected"

const App: React.FC = observer(() => {

  if (window.innerWidth >= 1024 ) {
    
    return <DesktopPage/>
  }

  const { camera, loader, geo } = useMainStore()  

  if (camera.status === "reject") {
    return <CameraRejectedPage/>
  }

  if (geo.status === "reject") {
    return <GeoRejectedPage/>
  }

  if (loader.loadStatus !== "complete") {
    return <GettingStartedPage/>
  }

  if (loader.status === 'complete') {
    return <ArPage/>
  }

  return <LoaderPage/>
})

export default App