
export const wrapText = (text: string, contarinerWidth: number, ctx: CanvasRenderingContext2D): [ string[], number ] => {
  const lines = text.split("\n")
  let maxWidth = 0
  for (let i = 0; i < lines.length; i++) {
    const width = ctx.measureText(lines[i]).width
    if (width > contarinerWidth) {
      let index = 0
      for (let j = 1; j < lines[i].length; j++) {
        if (lines[i][j] === " ") index = j
        const width = ctx.measureText(lines[i].slice(0, j)).width
        if (width >= contarinerWidth) {
          if (!index) index = j-1
          break
        }
      }

      const str = lines[i].slice(lines[i][index] === " "? index+1: index)
      maxWidth = Math.max(ctx.measureText(str).width, width)

      lines[i] = lines[i].slice(0, index)
      lines.splice(index, 0, str)
    } else {
      maxWidth = Math.max(maxWidth, width)
    }
  }
  return [ lines, maxWidth ]
}