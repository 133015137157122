import * as THREE from 'three'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import * as TWEEN from '@tweenjs/tween.js'

export const createCube = () => {
  const geometry = new THREE.BoxGeometry( 0.4, 0.4, 0.4 )
  const material = new THREE.MeshStandardMaterial ( {color: 0xffffff} )
  const cube = new THREE.Mesh( geometry, material )
  cube.position.set(0, 0.2, 0)
  return cube
}

export const createPlane = (aspect: number = 1) => {
  const geometry = new THREE.PlaneGeometry(1, 1/aspect)
  const material = new THREE.MeshStandardMaterial({ color: 0xffffff }) as THREE.Material
  const plane = new THREE.Mesh(geometry, material)
  plane.rotateX(-90 * THREE.MathUtils.DEG2RAD)
  return plane
}

export const addRoomLight = (scene: any) => {
  scene.addEventListener("addToRenderer", ({ renderer, scene }: { renderer: THREE.WebGLRenderer, scene: THREE.Scene }) => {
    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.02 ).texture
  })
}

export const findRecursive = (obj: THREE.Object3D, regexp: RegExp): THREE.Object3D[] => {
  const arr: THREE.Object3D[] = []
  for (let child of obj.children) {
    if (regexp.test(child.name)) {
      arr.push(child)
      if (child.children && child.children.length > 0) {
        arr.push(...findRecursive(child, regexp))
      }
    }
  }

  return arr
}

export const animateVector3 = (source: THREE.Vector3 | THREE.Euler, target: THREE.Vector3 | THREE.Euler, duration = 500) => {
  return new TWEEN.Tween({ x: source.x, y: source.y, z: source.z })
    .to({ x: target.x, y: target.y, z: target.z }, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(({ x, y, z }) => {
      source.x = x
      source.y = y
      source.z = z
    })
    .start()
}


export const clone = (obj: THREE.Object3D) => {
  const animations = obj.userData.animations
  const mixer = obj.userData.mixer
  delete obj.userData.animations
  delete obj.userData.mixer
  const newObj: THREE.Object3D = (SkeletonUtils as any).clone(obj)
  if (animations) {
    obj.userData.animations = animations
    newObj.userData.mixer = new THREE.AnimationMixer(newObj)
    animations.forEach((clip: THREE.AnimationClip) => newObj.userData.mixer.clipAction(clip).play())
  }
  obj.userData.animations = animations
  obj.userData.mixer = mixer
  return newObj
}
