import styles from './style.module.sass'
import cn from 'classnames'

const CameraRejectedPage = () => {

  return (
    <div className={cn(styles.container, 'h')}>
      <img src={require("./logo.png")} className={styles.imgLogo}/>
      <div className="stack">
      <div className="BigIcon">
        <img src={require("./icons/icon-camera.svg").default}/>
    </div>
        <h2>Дайте разрешения</h2>
        <div>Для правильной работы, нужно дать разрешение на использование <b>камеры.</b></div>        
      </div>
      <button className="btn"> Далее
        </button>
    </div>
  )
}

export default CameraRejectedPage
