import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app'
import "styles/globals.sass"
import "./utils/setup"
import { MainStoreProvider } from 'store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MainStoreProvider>
    <App />
  </MainStoreProvider>
);

 const calc = () => {
   const vh = window.innerHeight * 0.01
   document.documentElement.style.setProperty('--vh', `${vh}px`)
}
 window.addEventListener('resize', calc)
 calc()