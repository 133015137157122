import { useEffect, useState } from 'react'
import { animated, config, useTransition } from 'react-spring'
import crossPng from './cross.png'
import infoPng from './info.png'
import styles from './style.module.sass'
import { useMainStore } from 'store'

const SceneUI = () => {


 return (
    <>
      <animated.button 
        className={styles.btn}
        onClick={() => {(window.open('https://t.me/FermerGames_bot', '_blank'))}}
      >
        <svg width="20" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7492 3.48207C13.421 4.51492 8.76657 6.65513 1.78792 9.90059C0.654736 10.3819 0.0602141 10.8525 0.006348 11.3125C-0.0854238 12.0919 0.828305 12.3986 2.06922 12.8138C2.2388 12.8713 2.41436 12.9288 2.59392 12.9927C3.81687 13.4165 5.46079 13.9127 6.31467 13.9319C7.09074 13.9489 7.95658 13.6082 8.91221 12.9097C15.432 8.20972 18.7976 5.83525 19.0091 5.78414C19.1587 5.74793 19.3662 5.70108 19.5059 5.83525C19.6455 5.96728 19.6316 6.21857 19.6176 6.28672C19.5258 6.69772 15.9467 10.252 14.0913 12.0919C13.5128 12.6648 13.1038 13.0715 13.02 13.1652C12.8325 13.3718 12.6409 13.5698 12.4574 13.7594C11.3202 14.9264 10.4703 15.8038 12.5053 17.2348C13.4545 17.9027 14.2194 18.4582 14.9771 19.0084L15.045 19.0577C15.8968 19.6774 16.7467 20.295 17.848 21.0659C18.1273 21.2618 18.3946 21.4642 18.656 21.6622C19.6475 22.4182 20.5393 23.0954 21.6406 22.9889C22.279 22.925 22.9413 22.284 23.2765 20.3696C24.0685 15.8421 25.6286 6.03756 25.9898 1.99564C26.0117 1.66005 25.9983 1.32288 25.9498 0.990479C25.9208 0.722006 25.7983 0.474977 25.6067 0.298369C25.3214 0.0492099 24.8785 -0.00402912 24.679 0.000230019C23.7792 0.0172666 22.3987 0.530493 15.7492 3.48207Z" fill="#FFFFFF"/>
      </svg>
        Играть
      </animated.button>
    </>
  )
}

export default SceneUI