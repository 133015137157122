import styles from './style.module.sass'
import cn from 'classnames'

const DesktopPage = () => {

  return (
    <div className={cn(styles.containerdesk, 'h')}>
      <img src={require("./logo.png")} className={styles.img}/>
      <div className={styles.block}>
      <h1>Календарь с дополненной реальностью</h1>
      <span>Расширь возможности простого календаря! Наведи камеру своего устройства на QR-код и ты увидишь волшебство!</span>
      <img src={require("./qr.png")} className={styles.imgqr}/>
      <a href="https://t.me/FermerGames_bot" target="_blank" className={styles.btn}>Telegram</a>
        </div>
    </div>
  )
}

export default DesktopPage
