import { createContext, useContext, useEffect, useState } from 'react'
import Store from './store'

const StoreContext = createContext({} as Store)

export const MainStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [store] = useState(() => new Store())
  useEffect(() => {
    store.init()
    return () => store.dispose()
  }, [store])

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  )
}

export const useMainStore = () => {
  const store = useContext(StoreContext)
  return store
}
